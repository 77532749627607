import React, { useState, useEffect } from "react";
import models from "../constants/models";
import GltfViewer from "./GltfViewer";

function PortfolioItem({ model }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (open) {
      const handleKeyDown = (event) => {
        if (event.key === "Escape") {
          setOpen(false);
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open]);

  async function handleItemClick(e) {
    setLoading(true);
    setOpen(true);
    setSize({ x: window.innerWidth, y: window.innerHeight });
    setLoading(false);
  }
  return (
    <div className="col-md-4">
      <div className="work-box" onClick={handleItemClick}>
        <div className="work-img">
          <img src={model.imageURL} alt="" className="img-fluid" />
        </div>
        <div className="work-content">
          <div className="row">
            <div className="col-sm-8">
              <h2 className="w-title">{model.name}</h2>
              <div className="w-more">
                <span className="w-ctegory">{model.name}</span>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="w-like">
                <span className="ion-ios-plus-outline"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div
          id="preloader"
          style={{
            zIndex: 9999,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black",
          }}
        >
          {loading
            ? "Loading..."
            : [
                <GltfViewer
                  path={model.gltfURL}
                  width={size.x}
                  height={size.y}
                  background={model.background}
                />,
                <button
                  style={{ position: "fixed", zIndex: 99999, top: 5, right: 5 }}
                  class="fa fa-times-circle"
                  onClick={()=>setOpen(false)}
                ></button>,
              ]}
        </div>
      )}
    </div>
  );
}

class Portfolio extends React.Component {
  render() {
    return (
      <section id="portfolio" className="portfolio-mf sect-pt5 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Portfolio</h3>
                <p className="subtitle-a">
                Here you will find a collection of my 3D designs, each displayed as a thumbnail. By clicking on each thumbnail, you will be able to view the design in 3D mode and explore it from every angle.
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            {models.map((model) => (
              <PortfolioItem key={model.name} model={model} />
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
