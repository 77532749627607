

const models = [
    {
        name: "Bottle",
        imageURL: "/3d/Bottle/Bottle.png",
        gltfURL: "/3d/Bottle/Bottle.gltf"
    },
    {
        name: "Bottle2",
        imageURL: "/3d/Bottle/Bottle.png",
        gltfURL: "/3d/Bottle2/Bottle2.gltf"
    },
    {
        name: "Glue Tube",
        imageURL: "/3d/Glue Tube/Glue Tube.png",
        gltfURL: "/3d/Glue Tube/Glue Tube.gltf"
    },
    {
        name: "Glue White",
        imageURL: "/3d/Glue White/Glue White.png",
        gltfURL: "/3d/Glue White/Glue White.gltf",
        background: "abandoned_slipway_2k"
    },
    {
        name: "Paper",
        imageURL: "/3d/Paper/A4 Paper 500 Sheet.png",
        gltfURL: "/3d/Paper/A4 Paper 500 Sheet.gltf",
        background: "lilienstein_2k"
    },
    {
        name: "STABILO",
        imageURL: "/3d/STABILO/Stabilo Pen.png",
        gltfURL: "/3d/STABILO/STABILO.gltf",
        background: "noon_grass_2k"
    },
    {
        name: "Tank",
        imageURL: "/3d/Tank/View (2).png",
        gltfURL: "/3d/Tank/Tank.gltf",
        background: "oberer_kuhberg_2k"
    },
    {
        name: "Cube_1",
        imageURL: "/3d/Cube_1/Cube_1.png",
        gltfURL: "/3d/Cube_1/Cube_1.gltf",
        background: "small_harbor_01_2k"
    },
    {
        name: "Face_NewFile",
        imageURL: "/3d/Face_Test/Face_Test.png",
        gltfURL: "/3d/Face_Test/Face_Test.gltf",
        background: "abandoned_slipway_2k"
    },
]

export default models;