import React, { Suspense, useEffect, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF, Environment  } from '@react-three/drei';
import abandoned_slipway_2k from "../assets/backgrounds/abandoned_slipway_2k.hdr";
import lilienstein_2k from "../assets/backgrounds/lilienstein_2k.hdr";
import noon_grass_2k from "../assets/backgrounds/noon_grass_2k.hdr";
import oberer_kuhberg_2k from "../assets/backgrounds/oberer_kuhberg_2k.hdr";
import small_harbor_01_2k from "../assets/backgrounds/small_harbor_01_2k.hdr";
import suburban_parking_area_2k from "../assets/backgrounds/suburban_parking_area_2k.hdr";

const GltfModel = ({path, aspectratio}) => {
  const gltf = useGLTF(path, {
    extensions: ['KHR_materials_unlit', 'KHR_texture_transform']
  });
  const { cameras } = gltf;
  const set = useThree((state) => state.set);
  const camera = cameras[0];
  camera.aspect = aspectratio;
  camera.updateProjectionMatrix()

  useEffect(() => {
    if (camera) {
      set({camera});
    }
  }, [camera, set]);

  return <primitive object={gltf.scene} />;
};


function EnvironmentBackground({background}) {
  if(background === "abandoned_slipway_2k"){
    return <Environment files={abandoned_slipway_2k} background />
  }
  if(background === "lilienstein_2k"){
    return <Environment files={lilienstein_2k} background />
  }
  if(background === "noon_grass_2k"){
    return <Environment files={noon_grass_2k} background />
  }
  if(background === "oberer_kuhberg_2k"){
    return <Environment files={oberer_kuhberg_2k} background />
  }
  if(background === "small_harbor_01_2k"){
    return <Environment files={small_harbor_01_2k} background />
  }
  if(background === "suburban_parking_area_2k"){
    return <Environment files={suburban_parking_area_2k} background />
  }
  return <Environment preset="sunset" background />
}


const GltfViewer = ({path, width, height, background}) => {
  const controlsRef = useRef();

  return (
    <div style={{ width, height}}>
      <Canvas >
        <Suspense fallback={null}>
          <GltfModel path={path} aspectratio={width/height} />
          <EnvironmentBackground background={background} />
        </Suspense>
        <OrbitControls ref={controlsRef} />
      </Canvas>
    </div>
  );
};

export default GltfViewer;
